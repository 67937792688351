<template>
  <div class="lovely">
    <Panel :title="name" class="dark_bg">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$globalConfig.finishedText"
        @load="onLoad"
        :error.sync="error"
        offset:100
      >
        <div style="display: flex">
          <van-row class="list-list">
            <van-col v-for="(item, index) in lovelyListTwo" :key="index">
              <ul
                style="background-color: #fff"
                @click="goGoodsDeatail(item.id)"
              >
                <li>
                  <img v-lazy="item.img" class="img" />
                </li>
                <li
                  style="
                    margin: 0.27rem 0 0;
                    font-size: 0.26rem;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #333333;
                    line-height: 0.36rem;
                  "
                >
                  {{ item.title }}
                </li>

                <li
                  style="
                    margin: 0.17rem 0 0;
                    font-size: 0.22rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 0.3rem;
                  "
                >
                  {{ item.description }}
                </li>

                <li style="display: flex; margin: 0">
                  <span class="showPriceStyle">￥{{ item.showPrice }}</span>
                  <span
                    style="
                      color: #999;
                      font-size: 0.3rem;
                      text-decoration: line-through;
                    "
                    v-if="item.discountMoney"
                    >￥{{ item.minMoney }}</span
                  >
                </li>
                <li
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin: 0;
                  "
                >
                  <!-- <span
                  style="color: #999; font-size: 0.22rem; letter-spacing: 1px"
                >
                  已售{{ item.sales }}
                </span> -->
                  <span
                    class="scoreStyle"
                    v-if="item.score"
                    >{{ item.score }}积分</span
                  >
                </li>
              </ul>
            </van-col>
          </van-row>
          <van-row class="list-list">
            <van-col v-for="(item, index) in lovelyList" :key="index">
              <ul
                style="background-color: #fff"
                @click="goGoodsDeatail(item.id)"
              >
                <li>
                  <img v-lazy="item.img" class="img" />
                </li>
                <li
                  style="
                    margin: 0.27rem 0 0;
                    font-size: 0.26rem;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #333333;
                    line-height: 0.36rem;
                  "
                >
                  {{ item.title }}
                </li>

                <li
                  style="
                    margin: 0.17rem 0 0;
                    font-size: 0.22rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 0.3rem;
                  "
                >
                  {{ item.description }}
                </li>

                <li style="display: flex; margin: 0">
                  <span class="showPriceStyle">￥{{ item.showPrice }}</span>
                  <!-- >￥{{ (item.price / 100).toFixed(2) }}</span -->

                  <!-- <span
                  style="
                    color: #999;
                    font-size: 0.3rem;
                    text-decoration: line-through;
                  "
                  v-if="item.discountMoney"
                  >￥{{ item.minMoney }}</span
                > -->
                </li>
                <li
                  style="
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin: 0;
                  "
                >
                  <!-- <span
                  style="color: #999; font-size: 0.22rem; letter-spacing: 1px"
                >
                  已售{{ item.sales }}
                </span> -->
                  <span
                    class="scoreStyle"
                    v-if="item.score"
                    >{{ item.score }}积分</span
                  >
                </li>
              </ul>
            </van-col>
          </van-row>
        </div>
        <!-- <p slot="loading">
          <img
            src="@/assets/images/index.messenger-typing-preloader.svg"
            alt
            style="width: 20%"
          />
        </p> -->
        <loading-infinity slot="loading"></loading-infinity>
      </van-list>
    </Panel>
  </div>
  <!-- <div class="lovely">
    <Panel title="猜你喜欢" class="dark_bg">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$globalConfig.finishedText"
        @load="onLoad"
        :error.sync="error"
        offset:100
      >
        <van-row gutter="15">
          <van-col span="12" v-for="(item, index) in lovelyList" :key="index">
            <ul @click="goGoodsDeatail(item.id)">
              <li>
                <img v-lazy="item.imageURL" />
              </li>
              <li style="margin:.16rem 0 0;max-height: 40px;">{{item.name}}</li>
              <li style="display:flex;margin:0;">
                <span style="font-size:.26rem;color:#c93f3f;margin-right:0.2rem">￥{{item.moneyString}}</span>
                <span
                  style="color:#999;font-size:.22rem;text-decoration: line-through;"
                  v-if="item.discountMoney"
                >￥{{item.minMoney}}</span>
              </li>
              <li style="display:flex;flex-wrap:wrap;justify-content:space-between;margin:0;">
                <span style="color:#999;font-size:.22rem;letter-spacing:1px;">
                  已售{{item.soldNum}}
                </span>
                <span
                  style="color:#999;font-size:.22rem;letter-spacing:1px;color:#c93f3f;"
                  v-if="item.score"
                >{{item.score}}积分</span>
              </li>
            </ul>
          </van-col>
        </van-row>
        <p slot="loading">
          <img
            src="@/assets/images/index.messenger-typing-preloader.svg"
            alt
            style="width:20%;"
          />
        </p>
      </van-list>
    </Panel>
  </div> -->
</template>

<script>
import { Row, Col, List } from "vant";
import Panel from "@/components/common/panel";
import { mapGetters } from "vuex";
export default {
  props: ["name"],
  data() {
    return {
      lovelyList: [],
      lovelyListTwo: [],
      loading: false,
      finished: false,
      error: false,
      currentPage: 1,
      totalPage: Number,
      goGoodsDeatail: this.Base.goGoodsDetail,
    };
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [List.name]: List,
    Panel,
  },
  created() {},
  methods: {
    // 分页获取数据
    getGoods() {
      if (this.currentPage > this.totalPage) {
        this.loading = false;
        // 恢复currentPage和totalPage的值，以供分类分页使用
        return (this.finished = true);
      }
      //
      this.$api.home
        .guessYouLikeAddress({
          accessToken: localStorage.getItem("token"),
          pageNo: this.currentPage,
          customerId: localStorage.getItem("customerId"),
          storeId: localStorage.storeId,
        })
        .then((res) => {
          if (res.errno === 200) {
            this.totalPage = res.data.totalPageNo;
            this.loading = false; // 关闭上拉加载中
            if (this.currentPage === 1) {
              // res.data.items.forEach(v=>{
              //   console.log(v.title.length);
              //   if(v.title.length > 16){
              //     v.title = v.title.substring(0,18) + '...'
              //   }
              // })
              this.lovelyList = res.data.items;
              this.lovelyListTwo = this.lovelyList.splice(
                this.lovelyList.length / 2
              );
            } else {
              // this.lovelyList = this.lovelyList.concat(res.data.items);
              let arr = res.data.items;
              //  arr.forEach(v=>{
              //   console.log(v.title.length);
              //   if(v.title.length > 16){
              //     v.title = v.title.substring(0,18) + '...'
              //   }
              // })
              let arr1 = arr.splice(arr.length / 2);
              this.lovelyList = this.lovelyList.concat(arr);
              this.lovelyListTwo = this.lovelyListTwo.concat(arr1);
            }
            this.currentPage++;
          } else {
            this.error = true;
          }
        });
    },
    // 分页获取数据
    // getGoods() {
    //   if (this.currentPage > this.totalPage) {
    //     this.loading = false;
    //     // 恢复currentPage和totalPage的值，以供分类分页使用
    //     return (this.finished = true);
    //   }
    //   //
    //   this.$api.home
    //     .guessYouLikeAddress({
    //       accessToken: localStorage.getItem("token"),
    //       currentPage: this.currentPage,
    //       customerId: localStorage.getItem("customerId")
    //     })
    //     .then(res => {
    //       if (res.code === 100) {
    //         this.totalPage = res.returnValue.totalPage;
    //         this.loading = false; // 关闭上拉加载中
    //         if (this.currentPage === 1) {
    //           this.lovelyList = res.returnValue.data;
    //         } else {
    //           this.lovelyList = this.lovelyList.concat(res.returnValue.data);
    //         }
    //         this.currentPage++;
    //       } else {
    //         this.error = true;
    //       }
    //     });
    // },
    // 上拉加载
    onLoad() {
      // 异步获取数据
      setTimeout(() => {
        this.getGoods();
      }, 1000);
    },
  },
  computed: {
    ...mapGetters(["customerId"]),
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/styles/element.less";
.lovely {
  margin-top: 0.2rem;
  width: 100%;
  box-sizing: border-box;
  .van-col {
    margin-bottom: 0.3rem /* 15/50 */;
    ul {
      // background-color: #fff;
      // height: 5.9rem /* 240/50 */;

      box-sizing: border-box;
      font-size: 0.26rem /* 13/50 */;
      padding: 0 0 0.24rem /* 12/50 */;
      border-radius: 0.2rem /* 10/50 */;
      // box-shadow: 0px 6px 18px #dadce3;
      box-shadow: 0px 0px 0.2rem 0px rgba(0, 0, 0, 0.1);
      li {
        padding: 0 0.16rem /* 8/50 */;
        // line-height: 1.1rem;
        color: #544f4f;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: rows;
        -webkit-line-clamp: 2;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
        /* autoprefixer: on */
        &:first-child {
          padding: 0;
          height: 3.2rem /* 160/50 */;
        }
        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: 0.2rem /* 10/50 */;
          border-top-right-radius: 0.2rem /* 10/50 */;
        }
      }
    }
  }
}
</style>
<style lang="less" >
@import "../../assets/styles/element.less";
.lovely {
  .panel {
    box-sizing: border-box;
    background-color: transparent !important;
    .panel();
    padding: 0 0.3rem /* 15/50 */;
    h4 {
      padding: 0;
      text-align: left !important;
      font-size: 0.32rem !important;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
  }
}
.img {
  overflow: hidden;
}
.list-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding-right: 0.28rem;

  .van-col {
    width: 44vw;
  }
}
// 价格
.showPriceStyle {
  font-size: 0.3rem;
  color: @price_text_color;
  margin-top: 0.38rem;
  margin-right: 0.2rem;
}
// 积分
.scoreStyle {
  font-size: 0.22rem;
  letter-spacing: 1px;
  color: @score_text_color;
}
</style>

