<template>
    <div class="my dark_bg">
        <transition name="fade">
            <preLoading v-if="isLoading"></preLoading>
        </transition>
        <!-- 头部 -->
        <section class="my-header dark_bg dark_border" >
        <!-- <section class="my-header dark_bg dark_border" :style="{background: `url(${myHeadBackgroundImg})`, backgroundSize: '100%'}"> -->
            <p class="header-user-img">
                <img class='user-img' :src="user.avatarUrl" style="width:1.23rem;height:1.23rem" alt/>
                <!-- <span>手机用户{{ myInfo.mobile }}</span> -->
                <span style="color:#fff">{{user.nickname}}</span>
            </p>
            <!-- <p @click="service" class="dark_text">
                <span class="iconfont icon-kefu2 iconkefu"></span>
                客服中心
            </p> -->
            
             <p  class="dark_text" style='height:1rem'>
                 <span><img src="@/assets/images/setting.png" style="width:.44rem" alt="" @click="changeUserData"></span>
                 <!-- <span><img src="@/assets/images/setting.png" style="width:.44rem;margin-right:.37rem" alt="" @click="changeUserData"></span> -->
                 <!-- <span><img src="@/assets/images/kf.png" style="width:.44rem" alt="" @click="service"></span> -->
                <!-- <span class="iconfont icon-kefu2   icon-xiaoxi" style="font-size:.46rem;color:#fff;margin:0 0 0 .38rem" @click="service"></span> -->
            </p>
        </section>
        <!-- 订单-收藏-足迹 -->

        <ul class="my-tab">
            <li
                    v-for="item in tabList"
                    :key="item.id"
                    class="dark_text"
                    @click="goNext(item.path)"
            >
                <span style="font-width:bold;color:#fff">{{ item.num }}</span>
                <p style="color:#fff">{{ item.name }}</p>
            </li>
        </ul>

        <div class="my-order">
            <p class="my-order-top"><span>我的订单</span> <span class="look-all" @click='goOrderAll'>查看全部<img src="@/assets/images/more-title.png" style='width:.24rem'></span></p>
            <p>
                <ul class="order-list">
                    <li v-for="item in orderList" :key='item.start' @click='goOrderList(item.status,item.names)'>
                        <!-- <p class='order-list-img'><img :src="item.img" style="max-width:.58rem;max-height:.58rem" alt=""></p> -->
                        <p class='order-list-img'><span class="iconfont icon order-icon" :class="item.icon"></span></p>
                        <p>{{item.name}}</p>
                    </li>
                </ul>
            </p>
        </div>

        <!-- 资料卡片 -->
        <!-- <div class="my-card">
          <ul>
            <li>
              <p>
                <span>云楚新能源VIP</span>
                <span></span> -->
        <!-- 会员日102天，已为您节省501元-->
        <!-- </p> -->
        <!-- 去充值 -->
        <!-- <p></p>
            </li>
            <li>
              <p>
                我的积分：
                <span>{{ myInfo.score }}</span>
              </p>
              <p>
                我的余额：
                <span>0</span>
              </p>
            </li>
          </ul>
        </div> -->
        <!-- 我的信息入口 -->
        <section class="info-access">
            <div class='else-serve'>其他服务</div>
            <van-cell-group  :border='false'>
                <van-cell
                        title="收货地址"
                        value
                        is-link
                        to="/addresslist"
                        class="dark_shop_bg"
                >
                <!-- class="iconfont icon-dingwei"
                  slot="icon"
                  style="color: #dd7846" -->
          <!-- <span
           class='icon-width'
           slot="icon"
          >
          <img style='height:.36rem;margin-left:.08rem' src='@/assets/images/addres-icon.png'>
          </span>
                </van-cell>
                <van-cell
                        title="实名认证"
                        is-link
                        to="/changename"
                        class="dark_shop_bg"
                > -->
                  <!-- class="iconfont icon-iconzh1"
                slot="icon"
                style="color: #d14a4a" -->
        <span
                class='icon-width'
                slot="icon"
        >
          <img style='height:.36rem;margin-left:.08rem' src='@/assets/images/autonym -icon.png'>
        
        </span>
                </van-cell>
                <van-cell
                        title="手机号"
                        :value="myInfo.mobile"
                        is-link
                        to="/changetel"
                        class="dark_shop_bg"
                >
                  <!-- class="iconfont icon-shoujihao"
                  slot="icon"
                  style="color: #ddaa46" -->
          <span
                class='icon-width'
                slot="icon"
          >
          <img style='height:.36rem;margin-left:.08rem' src='@/assets/images/phone-icon.png'>
          
          </span>
                </van-cell>
                <!-- <van-cell title="现金劵" is-link to="/recharge" class="dark_shop_bg">
                  <span class="iconfont icon-yinhangqia-fill" slot="icon" style="color:#5A65D8"></span>
                </van-cell>-->
                <!-- <van-cell title="代金劵" is-link to="/coupon" class="dark_shop_bg">
          <span
                  class="iconfont icon-youhuiquan"
                  slot="icon"
                  style="color: #ed7667"
          ></span>
                </van-cell> -->
                <van-cell
                        title="火锅代金券"
                        is-link
                        to="/voucher"
                        class="dark_shop_bg"
                        v-if="hotPotAdmin"
                >
          <span
                  class="iconfont icon-youhuiquan2"
                  slot="icon"
                  style="color: #ed7667"
          ></span>
                </van-cell>
                <!-- <van-cell
                  title="清理缓存"
                  is-link
                  @click="clearStorage"
                  class="dark_shop_bg"
                > -->
                <!-- <van-cell title="清理缓存" is-link class="dark_shop_bg" @click="clearStorage">
                  <span
                    class="iconfont icon-qingchuhuancun"
                    slot="icon"
                    style="color: #d4a344"
                  ></span>
                </van-cell> -->
                 <van-cell title="发票抬头管理" is-link to="/controlOverInvoices" class="dark_shop_bg">
                <span
                class='icon-width'
                  slot="icon"
                 >
                 <img style='height:.36rem;margin-left:.08rem' src='@/assets/images/bill-icon.png'>
                 
                 </span>
                </van-cell>
                 <!-- <van-cell title="分销员申请" is-link to="/distributorApply" class="dark_shop_bg" v-if="!isDistribution">
                    <span
                    class='icon-width'
                    slot="icon"
                    >
                        <img style='height:.36rem;margin-left:.08rem' src='@/assets/images/distributor-icon.png'>
                    </span>
                </van-cell>
                
                <van-cell title="分销员中心" is-link to="/distributorInfo" class="dark_shop_bg" v-if="isDistribution">
                    <span
                    class='icon-width'
                    slot="icon"
                    >
                        <img style='height:.36rem;margin-left:.08rem' src='@/assets/images/distributorInfo-icon.png'>
                    </span>
                </van-cell> -->
                <!-- <van-cell title="邀请成为二级分销员" is-link to="/inviteDistributor" class="dark_shop_bg" v-if="isDistribution">
                    <span
                    class='icon-width'
                    slot="icon"
                    >
                        <img style='height:.36rem;margin-left:.08rem' src='@/assets/images/invite-icon.png'>
                    </span>
                </van-cell> -->
                <!-- <van-cell
                        title="退出账户"
                        is-link
                        @click="loginOut"
                        class="dark_shop_bg"
                >
          <span
                  class="iconfont icon-zhuxiaoguanji"
                  slot="icon"
                  style="color: #dd7846"
          ></span>
                </van-cell> -->
                <!-- <van-cell
                  title="分享云楚新能源"
                  is-link
                  @click="showShareList = true"
                  class="dark_shop_bg"
                >
                  <span
                    class="iconfont icon-fenxiang1"
                    slot="icon"
                    style="color: #dd7846"
                  ></span>
                </van-cell> -->
                <!-- <van-cell
                  title="检查更新"
                  is-link
                  @click="checkUpdate()"
                  class="dark_shop_bg"
                > -->
                <!-- <van-cell title="检查更新" is-link class="dark_shop_bg">
                  <span class="iconfont" slot="icon" style="color: #dd7846"
                    >&#xe72a;</span
                  >
                </van-cell> -->
                 <!-- <van-cell title="帮助中心" is-link to="/helpcenter" class="dark_shop_bg">
                    <p slot="icon" style="width: 0.48rem; margin-right: 0.32rem">
                        <img src="../../assets/images/helpCenter.png" style="width: 96%"/>
                    </p>
                </van-cell>
                <van-cell title="关于云楚新能源" is-link to="/aboutUs" class="dark_shop_bg">
                    <p slot="icon" style="width: 0.48rem; margin-right: 0.32rem">
                        <img src="../../assets/images/yunpin.png" style="width: 100%"/>
                    </p>
                </van-cell> -->
            </van-cell-group>
        </section>
       
<!-- 
        <div class="relation">
            --- 联系我们 : 0871-65353806 ---
        </div> -->
        <!-- 分享选择下拉窗 -->
        <!-- <van-popup
          v-model="showShareList"
          position="bottom"
          round
          class="share-popup"
          closeable
        >
          <ul class="share-list">
            <li @click="wxShareFriends('0')">
              <span
                class="iconfont icon-weixin-copy1"
                style="color: #03d510"
              ></span>
              <p>微信</p>
            </li>
            <li @click="wxShareFriends('1')">
              <span
                class="iconfont icon-pengyouquan-copy"
                style="color: #00df68"
              ></span>
              <p>朋友圈</p>
            </li>
          </ul>
        </van-popup> -->
        <Lovely name='为您推荐' style='padding-bottom:2rem'/>
        <NavBar v-if='!storeId' activeFlag='3'/>
        <NavBarAlone v-else activeFlag='3'/>

    </div>
</template>

<script>
import { Cell, CellGroup, Popup } from "vant";
import { removeToken } from "@/utils/auth";
import Lovely from "./lovely";

export default {
  data() {
    return {
      storeId: localStorage.storeId,
      tabList: [
        { name: "商品收藏", num: "", path: "/collect" },
        { name: "浏览足迹", num: "", path: "/footprint" },
        { name: "优惠券", num: "", path: "/coupon" },
      ],
      myInfo: {},
      isLoading: true,
      deviceEnvir: this.Base.deviceEnvir(),
      showShareList: false,
      hotPotAdmin: 0,
      orderList: this.$globalConfig.orderList,
      // orderList: [
      //   {
      //     name: "待付款",
      //     status: "1",
      //     names: "待付款",
      //     img: require("@/assets/images/待付款.png"),
      //   },
      //   {
      //     name: "待发货",
      //     status: "2",
      //     names: "待出库",
      //     img: require("@/assets/images/待发货.png"),
      //   },
      //   {
      //     name: "待收货",
      //     status: "3",
      //     names: "待收货",
      //     img: require("@/assets/images/待收货.png"),
      //   },
      //   {
      //     name: "待评价",
      //     status: "4",
      //     names: "待评价",
      //     img: require("@/assets/images/待评价.png"),
      //   },
      //   {
      //     name: "退换/售后",
      //     status: "5",
      //     names: "退换/售后",
      //     img: require("@/assets/images/售后.png"),
      //   },
      // ],
      user: {
        nickname: "未设置",
        avatarUrl: require("@/assets/images/qq.png"),
      },
      // isDistribution: localStorage.getItem('isDistribution'),
      isDistribution: "",
      myHeadBackgroundImg: "",
    };
  },
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Popup.name]: Popup,
    Lovely,
  },
  created() {
    // if (localStorage.getItem("theme") == "default") {
    //   this.url = "my-bk.png";
    // } else {
    //   this.url = "my-bk2.png";
    // }
    // this.myHeadBackgroundImg = require("@/assets/images/" + this.url);
    
    // this.orderList[0].img = require("@/assets/images/待付款.png")
      // this.orderList[1].img = require("@/assets/images/待发货.png")
      // this.orderList[2].img = require("@/assets/images/待收货.png")
      // this.orderList[3].img = require("@/assets/images/待评价.png")
      // this.orderList[4].img = require("@/assets/images/售后.png")
      
      // this.orderList[0].img = require("@/assets/images/待付款2.png")
      // this.orderList[1].img = require("@/assets/images/待发货2.png")
      // this.orderList[2].img = require("@/assets/images/待收货2.png")
      // this.orderList[3].img = require("@/assets/images/待评价2.png")
      // this.orderList[4].img = require("@/assets/images/售后2.png")
    // console.log('是否默认',localStorage.getItem("theme") == 'default');
    if (!localStorage.getItem("appid")) {
      this.getConfigAppId();
    }
    this.getData();
    window.clearCacheCallback = this.clearCacheCallback;
  },
  methods: {
    getConfigAppId() {
      this.$api.login
        .getConfigLogoUrl({
          k: "APPID",
        })
        .then((res) => {
          // 成功
          if (res.errno === 200) {
            localStorage.setItem("appid", res.data.valueWorth);
          } else {
            this.$toast(res.errmsg);
          }
        });
    },
    checkUpdate() {
      if (this.deviceEnvir === "IOS") {
        try {
          window.webkit.messageHandlers.checkUpdate.postMessage("1");
        } catch (error) {
          this.$root.eventHub.$emit("update", "1");
        }
      }
      if (this.deviceEnvir === "ANDROID") {
        try {
          window.revisionInterface.checkUpdate();
        } catch (error) {
          this.$root.eventHub.$emit("update", "1");
        }
        //   try {
        //     this.$toast(1)
        //     this.$root.eventHub.$emit("update", "1");

        // } catch (error) {

        //   window.revisionInterface.checkUpdate();
        // }
      }
    },

    goNext(url) {
      // console.log(url);
      // return
      this.$router.push(url);
    },
    changeUserData() {
      this.$router.push("/information");
    },
    goOrderAll() {
      this.$router.push({ path: "/myorder", query: { status: 0 } });
    },
    goOrderList(status, names) {
      this.$router.push({ path: "/myorder", query: { status, names } });
    },
    service() {
      this.$router.push("/customerChat");
    },
    // 分享给朋友
    wxShareFriends(type) {
      var personalNumber = localStorage.getItem("personalNumber"),
        link = `${
          window.location.href.split("#")[0]
        }#/register?referralCode=${personalNumber}`,
        imageUrl =
          "http://keplerinfo.oss-cn-shenzhen.aliyuncs.com/core/53BFDA69/2B3F4D68/8BE3E279/EA8BC711.png?Expires=1883455672&OSSAccessKeyId=LTAI2S35M3YS4SiT&Signature=FQ%2Fw%2FxNqJgDrYNUKcuiGm%2BSnhgk%3D",
        name = "",
        description = "";
      // 后台记录分享
      this.$server.shareApi
        .shareYPAppAddress({
          accessToken: localStorage.getItem("token"),
          customerId: localStorage.getItem("customerId"),
          shareLink: link,
          shareImage: imageUrl,
          weChatType: type,
        })
        .then((res) => {
          if (res.code === 100) {
            name = res.returnValue.shareTitle;
            description = res.returnValue.shareContent;
            var paramsObj = {
              cmd: "012", // 方法名
              link, // 分享跳转地址
              imageUrl, // 缩略图地址
              name, // 名字
              description,
              type, // 类型  0聊天界面，1朋友圈，2收藏，3联系人
            };
            // IOS
            if (this.deviceEnvir === "IOS") {
              window.webkit.messageHandlers.invokeiOS.postMessage(paramsObj);
            } else if (this.deviceEnvir === "ANDROID") {
              window.android.invokeAndroid(JSON.stringify(paramsObj));
            }
          }
        });
    },
    // 清除缓存
    // clearStorage() {
    //   let u = navigator.userAgent;
    //   let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // 判断是否是 android终端
    //   let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 ios终端
    //   if (isAndroid === true) {
    //     // 安卓
    //     window.android.invokeAndroid("cmd003");
    //     return "Android";
    //   } else if (isIOS === true) {
    //     // Iphone清除缓存
    //     var paragramStr = ["cmd003"];
    //     window.webkit.messageHandlers.clearCache.postMessage(paragramStr);
    //     return "IOS";
    //   } else {
    //     return "PC";
    //   }
    // },
    // 退出登录
    loginOut() {
      var userId = localStorage.getItem("customerId");
      this.$api.login
        .loginOutAddress({
          userId,
        })
        .then((res) => {
          if (res.errno === 200) {
            this.$toast({
              message: "退出成功",
              forbidClick: true,
              duration: 800,
              onClose: () => {
                this.$router.push("index");
              },
            });
            // 清除token
            localStorage.removeItem("token");
            localStorage.removeItem("customerLevel");
            localStorage.removeItem("customerName");
            localStorage.removeItem("mobile");
            // 修改customerID
            localStorage.removeItem("customerId");
            removeToken();
          } else {
            this.$toast.fail("退出失败，不可预知的错误");
          }
        });
    },

    // 获取我的数据信息
    getData() {
      this.$api.my
        .ownDetailAddress({
          accessToken: localStorage.getItem("token"),
          userId: localStorage.getItem("customerId"),
          storeId: localStorage.storeId,
        })
        .then((res) => {
          // console.log(res);
          this.isLoading = false;
          if (res.errno === 200) {
            console.log("是否成为分销员", res);
            localStorage.setItem("isDistribution", res.data.isDistribution);
            this.isDistribution = res.data.isDistribution;
            for (let obj of this.tabList) {
              obj.name === "商品收藏"
                ? (obj.num = res.data.collectNum)
                : obj.name === "优惠券"
                ? (obj.num = res.data.couponNum)
                : (obj.num = res.data.footPrintNum);
            }
            if (res.data.user) {
              // this.user = res.data.user
              if (res.data.user.nickname)
                this.user.nickname = res.data.user.nickname;

              if (res.data.user.avatarUrl)
                this.user.avatarUrl = res.data.user.avatarUrl;
            }
            let userInfo = {
              ...res.data.user,
            };
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            // this.hotPotAdmin =
            //   res.returnValue.hotPotAdmin == 0
            //     ? true
            //     : localStorage.getItem("hotPotAdmin") == 0
            //     ? true
            //     : false;
            // this.myInfo = res.returnValue;
            // this.tabList[0].num = this.myInfo.orderNum;
            // this.tabList[1].num = this.myInfo.collectionNum;
            // this.tabList[2].num = this.myInfo.footPrint;
            this.myInfo.mobile = localStorage.mobile;
          }
        });
    },
    // 去充值
    goGrow() {
      this.$toast({
        message: "敬请期待云楚新能源的充值功能",
        duration: 800,
      });
    },
    // 微信分享
    wxShare() {
      let self = this;
      switch (self.deviceEnvir) {
        case "IOS":
          let paramsObj = { cmd: "012" };
          window.webkit.messageHandlers.invokeiOS.postMessage(paramsObj);
          break;
        default:
          break;
      }
    },

    // 清除缓存
    clearStorage() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // 判断是否是 android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 ios终端
      if (isAndroid === true) {
        window.android.invokeAndroid("cmd003");
        this.$toast("清理成功");
        this.$router.push("/index");

        return "Android";
      } else if (isIOS === true) {
        // Iphone清除缓存
        var paragramStr = ["cmd003"];

        window.webkit.messageHandlers.clearCache.postMessage(paragramStr);

        return "IOS";
      } else {
        return "PC";
      }
    },
    clearCacheCallback(num) {
      if (num == 1) {
        this.$toast("清理成功");
        this.$router.push("/index");
      } else {
        this.$toast("清理失败");
      }
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/styles/element.less";

// @red: #c83f3f;
.my {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-color: transparent;
  padding-bottom: 1.28rem /* 64/50 */;

  .my-header {
    width: 100%;
    height: 5.18rem;
    background: @header_background;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    // background-image: url(../../assets/images/my-bk.png);
    // background-size: 100%; /*设置图片大小*/
    box-sizing: border-box;
    .list(row);
    padding: 1.04rem 0.32rem /* 16/50 */ 0.12rem /* 6/50 */;
    justify-content: space-between;
    // background-color: #fff;
    // align-items: center;
    letter-spacing: 1px;
    // border-bottom: 1px solid #e2dfdfa1;
    margin: 0 0 0.12rem /* 6/50 */;
    text-align: center;
    font-size: 0.28rem /* 14/50 */;

    & > p {
      &:first-child {
        // .list(row);
        // align-items: center;

        img {
          width: 0.76rem /* 38/50 */;
          margin: 0 0.2rem /* 10/50 */ 0 0;
        }

        span {
          padding-top: 0.46rem;
          // color: @red;
        }
      }

      &:last-child {
        text-align: center;
        position: relative;
        font-size: 0.24rem /* 12/50 */;

        .iconkefu {
          display: block;
          font-size: 0.44rem /* 22/50 */;
          font-weight: bold;
        }

        .position {
          // background-color: @red;
          color: #fff;
          width: 0.32rem /* 16/50 */;
          font-size: 0.22rem /* 11/50 */;
          height: 0.32rem /* 16/50 */;
          // padding: 0.1rem;
          line-height: 0.32rem /* 16/50 */;
          border-radius: 50%;
          text-align: center;
          position: absolute;
          top: -1rem;
          right: -1rem;
        }
      }
    }
  }

  .my-tab {
    .list(row);
    margin-top: -2.88rem;
    padding: 0.32rem /* 16/50 */ 0;
    justify-content: space-around;
    margin-bottom: 0.12rem /* 6/50 */;
    color: #fff;

    li {
      text-align: center;
      font-size: 0.26rem /* 13/50 */;
      color: #333;
      width: 30%;

      &:nth-child(2) {
        // border: 1px solid #ccc;
        position: relative;
        border-top: none;
        border-bottom: none;

        &::after {
          position: absolute;
          top: 0.38rem;
          left: -0.26rem;
          content: "";
          width: 2px;
          height: 0.25rem;
          background: #f4f4f4;
        }
        &::before {
          position: absolute;
          top: 0.38rem;
          right: -0.26rem;

          display: block;
          content: "";
          width: 2px;
          height: 0.25rem;
          background: #f4f4f4;
        }
      }

      span {
        font-size: 0.32rem /* 16/50 */;
      }
    }
  }

  .my-card {
    padding: 0 0.32rem /* 16/50 */;
    margin-bottom: 0.44rem /* 22/50 */;

    ul {
      padding: 0.32rem /* 16/50 */ 0.44rem /* 22/50 */;
      // background-color: @red;
      color: #fff;
      border-radius: 0.2rem;

      li {
        .list(row);
        justify-content: space-between;
        font-size: 0.26rem /* 13/50 */;

        &:first-child {
          p {
            &:first-child {
              span {
                display: block;
                line-height: 0.38rem /* 19/50 */;

                &:first-child {
                  font-size: 0.32rem /* 16/50 */;
                }

                &:last-child {
                  font-size: 0.22rem /* 11/50 */;
                }
              }
            }

            &:last-child {
              letter-spacing: 1px;
              // border: 1px solid #fff;
              height: 0.32rem /* 16/50 */;
              padding: 0.03rem /* 1.6/50 */ 0.06rem /* 3.2/50 */;
              text-align: center;
              line-height: 0.32rem /* 16/50 */;
              font-size: 0.24rem /* 12/50 */;
              border-radius: 0.24rem /* 12/50 */;
            }
          }
        }

        &:last-child {
          margin: 0.64rem /* 32/50 */ 0 0;

          p {
            width: 50%;

            &:last-child {
              text-align: right;
              border-left: 1px solid #fff;
            }
          }
        }
      }
    }
  }

  .info-access {
    position: relative;
    margin: 0 0.3rem;
    padding: 1rem 0.3rem 0.1rem;
    background: #ffffff;
    box-shadow: 0px 0px 0.1rem 0px rgba(0, 0, 0, 0.05);
    border-radius: 0.2rem;
    .else-serve {
      position: absolute;
      top: 0.3rem;
      left: 0.3rem;
      font-size: 0.32rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .van-cell {
      // letter-spacing: 1px;
      &::after {
        left: 0.08rem;
        width: 100%;
      }
      & > span {
        font-size: 0.44rem /* 22/50 */;
        margin-right: 0.32rem /* 16/50 */;
        // margin-left: .32rem;
      }

      .van-cell__title {
        font-size: 0.28rem /* 14/50 */;
        color: #333;
      }

      .van-cell__value {
        font-size: 0.24rem /* 12/50 */;
        font-weight: 500;
        font-family: -apple-system, BlinkMacSystemFont, SF Pro text, STHeiti,
          Microsoft YaHei, Helvetica, Arial, sans-serif;
      }
    }
  }

  .share-popup {
    .share-list {
      .list(row);
      box-sizing: border-box;
      padding: 2.4rem 1rem 1rem;

      li {
        flex: 1;
        font-size: 0.22rem /* 11/50 */;
        text-align: center;

        span {
          font-size: 0.64rem /* 32/50 */;
        }

        p {
          line-height: 0.44rem /* 22/50 */;
        }
      }
    }
  }
}

.relation {
  position: absolute;
  bottom: 2rem;
  width: 100%;
  text-align: center;
  font-size: 0.26rem;
  color: #aaa;
}
.header-user-img {
  display: flex;
  align-content: flex-start;
  margin-left: 0.12rem;
  .user-img {
    border: 0.04rem solid #ffffff;
    box-shadow: 0px 0px 0.18rem 0px rgba(0, 0, 0, 0.57);
    border-radius: 50%;
  }
}
.my-order {
  margin: 0.36rem 0.3rem 0.3rem;
  background: #ffffff;
  box-shadow: 0px 0px 0.1rem 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.2rem;
  .my-order-top {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem 0.3rem 0.4rem;
    &:first-child {
      font-size: 0.32rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .look-all {
      display: flex;
      align-items: center;
      font-size: 0.28rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #aeaeae;
    }
  }
}
.order-list {
  display: flex;
  justify-content: space-around;
  padding-bottom: 0.3rem;
  font-size: 0.26rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
  > li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:nth-child(2),
    &:nth-child(3) {
      span {
         font-size: 24px;
      }
    }
  }
}
/deep/ .van-cell {
  padding: 0.2rem 0;
}
.icon-width {
  text-align: center;
  width: 0.48rem;
}
.order-list-img {
  height: 0.58rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.van-cell__value {
  color: #333;
}
.van-cell__right-icon {
  // width: .1rem;
  // height: .2rem;
  font-size: 0.24rem;
  color: #4f4f4f;
}
.order-icon {
  font-size: 0.6rem /* 30/50 */;
  color: @ordinary_icon_color;
}
</style>
<style >
body {
  background-color: #f6f6f9;
}
</style>
